import { Grid } from '@material-ui/core';
import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { UserInputData } from "../../pages/StockForm";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button:{
    margin: '15px 10px 0 2px',
  }
}))

function Confirm(props) {
  const classes = useStyles();
  const { currentState } = useContext(UserInputData);
  const onSubmit = () => {
		// http送信先
    const ip = process.env.REACT_APP_API_KEY
    let url = `${ip}/koji_manage/postDiscordCustomer`;
    axios.post(url,currentState.form, {
      headers: {
      'content-type': 'application/json'
      }
    })
    .then(res => {
      alert('決済登録画面へ遷移します。');
      window.location.href = 'https://bit.ly/2T8SsjY';
      
    })
    .catch((error) => {
      console.log(error)      
    })
  };
  const inputDataLists = {
    full_name     : '',
    kana          : '',
    mail          : '',
    discordId     : '',
    stock         : '1',
  };
  
  for ( var k in currentState) {
    for ( var v in currentState[k]) {
      var value = ''
      if (currentState[k][v] === true) {
        value = 'チェックしました';
      } else if (currentState[k][v] === false) {
        value = 'チェックしていません';
      } else if (currentState[k][v] === '') {
        value = '未入力';
      } else {
        value = currentState[k][v];
      }
        inputDataLists[v] = value;
    }
  }
  return (
    <Grid container >
      <TableContainer component={Paper}>
        <Table aria-label="Customer Input Data">
          <TableHead >
            <TableRow >
              <TableCell>項目</TableCell>
              <TableCell>入力内容</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>          
            <TableRow>
              <TableCell>メール</TableCell>
              <TableCell>{inputDataLists.mail}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DiscordID</TableCell>
              <TableCell>{inputDataLists.discordId}</TableCell>
            </TableRow>          
          </TableBody>
        </Table>
      </TableContainer>
      <Button className={classes.button} variant="contained" color="primary" onClick={props.handleBack}>
        戻る
      </Button>
      <Button className={classes.button} variant="contained" color="primary" onClick={onSubmit}>
        送信
      </Button>
    </Grid>
  )
}

export default Confirm