import React from 'react';
import Home from './pages/MemberList';
import Form from './pages/MemberForm';
import Discord from './pages/DiscordForm';
import Stock from './pages/StockForm';
import Header from './components/Header';
import LoggedIn from './components/LoggedIn'; /** 追加 **/
import LoggedOut from './components/LoggedOut'; /** 追加 **/
import Auth from './Auth';
import {MuiThemeProvider} from '@material-ui/core/styles'
import {theme} from './materialui/theme'
import {} from 'react-router-dom'
import { BrowserRouter as Router, Route} from 'react-router-dom';
import { CookiesProvider, withCookies } from 'react-cookie';

function App() {  
  // const id = useParams()
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <div className="App">
          { /** 変更 **/ }
          <CookiesProvider>
              <LoggedIn>
                <Header />
                  <Route path="/" exact component={Home} />
                {/* <Route path="/post/:postId/" component={Post} /> */}
              </LoggedIn>
              <LoggedOut>
                <Route path="/auth" component={Auth} />
              </LoggedOut>
          </CookiesProvider>
        </div>
          <Route path="/form/:id" component={Form} />
          <Route path="/Discord/" component={Discord} />
          <Route path="/Stock/" component={Stock} />
      </Router>
    </MuiThemeProvider>
  );
}

export default withCookies(App); /** 変更 **/