import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";

export default function HeaderForm() {
  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: 1,
    },
    title: {
      marginTop: 5,
      marginBottom: 5,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 20,
    },
    header: {
      
    } 
  }));
  const classes = useStyles();
  return(
    <>
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12} lg={8}>
        <AppBar position="static" className={classes.root}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item className={classes.title}>【コジサロン】参加申し込みフォーム</Grid>
          </Grid>
        </AppBar>
      </Grid>
    </Grid>
    </>
  )
}