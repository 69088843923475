import React, {useState}  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { Button,TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Img from './image/logos/koji.png'

/**
* @author
* @function Auth
**/
const useStyles = makeStyles((theme) => ({
  root:{
    marginTop: 20,
    height:650,
    width:400
  },
  formMargin:{
    margin:'10px'
  },
  media:{
    width:'100%',
  }
}));
const Auth = (props) => {
  const classes = useStyles();
  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ loginFunction, setLoginFunction ] = useState(true);
  const [ errorMessage, setError ] = useState("");

  const auth = (event) => {
    event.preventDefault();
    let form_data = new FormData();

    form_data.append('username', username);
    form_data.append('password', password);
    

    const url = process.env.REACT_APP_API_KEY

    const postUri = loginFunction ? `${url}/api/token/` : `/api/users/`;

    axios.post(postUri, form_data, {
        headers: {
            'Content-Type': 'application/json'
        },
    })
    .then( res => {
      console.log(res)
        props.cookies.set('blog-token', res.data.token);
        window.location.href = "/";
    })
    .catch((error)=> {
      setError(error)
    });
  }

  return(
    <Grid container justifyContent="center">
      <form onSubmit={auth}>
        <Card className={classes.root}>
          <CardActionArea>
              <CardMedia
                component="img"
                className={classes.media}
                image={Img}
                title="Contemplative Reptile"
              />
            <CardContent>
              <Grid className={classes.formMargin} container justifyContent="center">
                <TextField
                  required
                  size="small"
                  id="username"
                  label='ID'
                  variant="filled"
                  onChange={(e) => setUsername(e.target.value)}
                />
                { errorMessage ? <p className="red">{errorMessage}</p> : null }
              </Grid>
              <Grid className={classes.formMargin} container justifyContent="center">
                <TextField
                  required
                  size="small"
                  id="password"
                  type="password"
                  label='パスワード'
                  variant="filled"
                  onChange={(e) => setPassword(e.target.value)}
                />
                { errorMessage ? <p className="red">{errorMessage}</p> : null }
              </Grid>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Grid className={classes.formMargin} container justifyContent="center">
              <Button variant="contained" color="primary" type="submit" >{loginFunction ? 'ログイン' : 'サインイン'} </Button>
            </Grid>
          </CardActions>
        </Card>
      </form>
    </Grid>
   )

 }

export default withCookies(Auth)