import { Grid } from '@material-ui/core';
import React from "react";
import Typography from '@material-ui/core/Typography';



function Confirm(props) {
  return (
    <>
    <Grid container alignItems="center" justify="center">
      <Grid item xs={12}>
        <Typography variant="h6" component="div" >
          コジサロンへの入会承認が完了しました。       
        </Typography>
      </Grid>
    </Grid>
  </>
  )
}
export default Confirm