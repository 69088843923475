import React, { useContext, useEffect, useState } from "react";
import { UserInputData } from "../../pages/MemberForm";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from '@material-ui/core';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth:600
  },
  inputFields: {
    margin: 15,
    minWidth: 250,
  },
  inputLabels: {
    marginBottom:2,
    paddingLeft: 14,
  },
  submit:{
    marginTop:80,
    marginBottom:80
  },
  selectValue : {
    margin: 15,
    minWidth: 250,
    textAlign: "left"
  },
  checkbox : {
    marginTop: 20    
  }
}))

export default function EntryForm(props) {
  const classes = useStyles();
  const [mailList, setMailList] = useState([]);
  const [policy, setPolicy ] = useState(false)

  //validate
  const basicSchema = Yup.object().shape({
    full_name: Yup.string()
      .required('必須項目です'),
    kana: Yup.string()
      .required('必須項目です'),  
    mail: Yup.string()
      .required('必須項目です')
      .email('メールアドレスの形式が不正です')
      .test('email-test', '過去に登録されたメールアドレスです。管理者へ問い合わせください',(inputMailAddress) => {
        if (mailList.findIndex( value => value.mail === inputMailAddress) === -1) {
          return true;
        } else {
          return false;
        }
      }),       
    prefecture_id: Yup.string()
      .required('必須項目です'), 
    tel: Yup.string()
      .required('必須項目です')
      .max(11, '11文字以内で入力してください(ハイフンなし)'),
    age: Yup.number()
      .required('必須項目です')
      .positive('値が不正です')
      .max(99, '正しい年齢を入力してください')
      .min(1, '正しい年齢を入力してください')
      .integer('整数で指定してください'),
    occupation_id: Yup.string()
      .required('必須項目です'),  
    payment_id: Yup.string()
      .required('必須項目です'),  
    line_name: Yup.string()
      .required('必須項目です')
  });  

  const { currentState, setCurrentState, id } = useContext(UserInputData);

  // フォーム送信用
  const {control, handleSubmit, setValue, formState:{ errors } } = useForm({
    mode: 'onBlur',
    defaultValues: {
      full_name         : '',
      kana              : '',
      mail              : '',
      prefecture_id     : '',
      tel               : '',
      age               : '',
      occupation_id     : '',
      line_name         : '',
      payment_id        : '',
      payment_status_id : '1',
      status_id         : '1',
      afiliate_code     : id !== 'regist'? id : '',
      join_date         : moment(Date()).format('YYYY-MM-DD'),
      fx                : 1,
      stock             : 4,
    },
    resolver: yupResolver(basicSchema)

  });

  const onSubmit = (data) => {
    props.handleNext();
    setCurrentState({...currentState, "form": data });
  };

  const [occupations, setOccupations] = useState([]);
  const [prefectures, setPrefectures] = useState([]);
  const [payments, setPayments] = useState([]);
  const form = currentState["form"];

  useEffect(() => {
    const url = process.env.REACT_APP_API_KEY
    const getData = async () => {
      const response1 = await axios.get(`${url}/koji_manage/getOccupations`);
      setOccupations(response1.data)
      const response2 = await axios.get(`${url}/koji_manage/getPrefectures`);
      setPrefectures(response2.data)
      const response3 = await axios.get(`${url}/koji_manage/getPaymets`);
      setPayments(response3.data)
      const response4 = await axios.get(`${url}/koji_manage/getMailList`);
      setMailList(response4.data)
    }

    if (form) {
      setValue("full_name", currentState["form"]["full_name"]);
      setValue("kana", currentState["form"]["kana"]);
      setValue("tel", currentState["form"]["tel"]);
      setValue("age", currentState["form"]["age"]);
      setValue("line_name", currentState["form"]["line_name"]);
      setValue("occupation_id", currentState["form"]["occupation_id"]);
      setValue("prefecture_id", currentState["form"]["prefecture_id"]);
      setValue("payment_id", currentState["form"]["payment_id"]);
      setValue("status_id", currentState["form"]["status_id"]);
      setValue("mail", currentState["form"]["mail"]);
    }
    getData()
  }, [])

  // 利用規約同意時に「次へ」ボタンがTrue
  const handleChange = () => {
    setPolicy(!policy)
  }

  return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item lg={12}>
            <Controller
              control={control}
              name='full_name'
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <TextField
                      className={classes.inputFields}
                      label='名前　例)山田太郎'
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={errors.full_name ? true : false}
                      helperText={errors.full_name?.message}
                    ></TextField>
                  }
                />
              )}
            />
          </Grid>
          <Grid item lg={12}>
            <Controller
              control={control}
              name='kana'
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <TextField
                      className={classes.inputFields}
                      label='カナ 例)ヤマダタロウ'
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={errors.kana ? true : false}
                      helperText={errors.kana?.message}
                    ></TextField>
                  }
                />
              )}
            />
          </Grid>
          <Grid item lg={12}>
            <Controller
              control={control}
              name='mail'
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <TextField
                      className={classes.inputFields}
                      label='メール 例)abc123@gmail.com'
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={errors.mail ? true : false}
                      helperText={errors.mail?.message}
                    ></TextField>
                  }
                />
              )}
            />
          </Grid>
          <Grid item lg={12}>
            <Controller
              control={control}
              name='tel'
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                      <TextField
                        className={classes.inputFields}
                        label="電話  例)09012345678"
                        InputLabelProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                        variant="outlined"
                        type="tel"
                        value={value}
                        onChange={onChange}
                        error={errors.tel ? true : false}
                        helperText={errors.tel?.message}
                      ></TextField>
                  }
                />
              )}
            />
          </Grid>            
          <Grid item lg={12}>
            <Controller
              control={control}
              name='age'
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <TextField
                      className={classes.inputFields}
                      label='年齢'
                      variant="outlined"
                      type="number"
                      value={value}
                      onChange={onChange}
                      error={errors.age ? true : false}
                      helperText={errors.age?.message}
                    ></TextField>
                  }
                />
              )}
            />
          </Grid>
          <Grid item lg={12}>
            <Controller
              control={control}
              name='occupation_id'
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <FormControl>
                      <TextField
                        className={classes.selectValue}
                        select
                        label="職業"
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        error={errors.occupation_id ? true : false}
                        helperText={errors.occupation_id?.message}
                    >
                      <MenuItem></MenuItem>
                      { occupations.map((value, index) => (
                      <MenuItem key={index} value={ value.id }>{ value.name }</MenuItem>
                    )) }
                    </TextField>
                  </FormControl>
                  }
                />
              )}
            />
          </Grid>
          <Grid item lg={12}>
            <Controller
              control={control}
              name='prefecture_id'
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <FormControl>
                      <TextField
                        className={classes.selectValue}
                        label="お住まいの都道府県"
                        select
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        error={errors.prefecture_id ? true : false}
                        helperText={errors.prefecture_id?.message}
                      >
                      {prefectures.map((value, index) => (
                        <MenuItem key={index} value={ value.id }>{ value.name }</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  }
                />
              )}
            />
          </Grid>    
          <Grid item lg={12}>
            <Controller
              control={control}
              name='line_name'
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <TextField
                      className={classes.inputFields}
                      label='LINEの名前 例)コジコジ'
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={errors.line_name ? true : false}
                      helperText={errors.line_name?.message}
                    ></TextField>
                  }
                />
              )}
            />
          </Grid>
          <Grid item lg={12}>
            <Controller
              control={control}
              name='payment_id'
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <FormControl>
                      <TextField
                        select
                        className={classes.selectValue}
                        label="支払い方法"
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        error={errors.payment_id ? true : false}
                        helperText={errors.payment_id?.message}
                    >
                      <MenuItem></MenuItem>
                      { payments.map((value, index) => (
                      <MenuItem key={index} value={ value.id }>{ value.name }</MenuItem>
                    )) }
                    </TextField>
                  </FormControl>
                  }
                />
              )}
            />
          </Grid>          
          <Grid item>
            <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={policy}
                onClick={() => handleChange()}
              />
            }
            label="利用規約に同意する"
          />
          </Grid>          
          <Grid item>            
            <a target="_blank" href="https://drive.google.com/file/d/1U823vWcTG1wsjaDqWxY9-YqDe03bQFR8/view">コジサロン利用規約</a>
          </Grid>          
          <Grid item>
            <Button
              className={classes.checkbox}
              disabled={!policy}
              variant="contained"
              color="primary"
              type="submit"
            >
                次へ
            </Button> 
          </Grid>
        </form>
  );
}
