import React, { useContext, useState, useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { TableContext } from "../../pages/MemberList";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 500,
  },
  inputFields: {
    margin: 15,
    minWidth: 250,
  },
  inputLabels: {
    marginBottom: 2,
    paddingLeft: 14,
  },
  submit: {
    marginTop: 80,
    marginBottom: 80,
  },
  selectValue : {
    margin: 15,
    minWidth: 250,
    textAlign: "left"
  },
  memoInputFields: {
    margin: 15,
    minWidth: 525,
  },
}));
export default function EditDialog(props) {
  const classes = useStyles();
  const { dialog, setDialog } = useContext(TableContext);
  const [form, setForm] = useState([]);
  const [occupations, setOccupations] = useState([]); //職業
  const [prefectures, setPrefectures] = useState([]); //都道府県
  const [payments, setPayments] = useState([]); //支払い方法
  const [paymentstatus, setPaymentStatus] = useState([]); //決済有無
  const [status, setStatus] = useState([]); //ステータス
  const [stocks, setStocks] = useState([]); //株
  const [putUrl, setPutUrl] = useState(); 
  const basicSchema = Yup.object().shape({
    full_name: Yup.string()
      .required('必須項目です'),
    kana: Yup.string()
      .required('必須項目です'),  
    mail: Yup.string()
      .required('必須項目です')
      .email('メールアドレスの形式が不正です'),       
    prefecture_id: Yup.string()
      .required('必須項目です'), 
    tel: Yup.string()
      .required('必須項目です')
      .max(11, '11文字以内で入力してください(ハイフンなし)'),
    age: Yup.string()
      .required('必須項目です')
      .max(3, '正しい年齢を入力してください') ,  
    occupation_id: Yup.string()
      .required('必須項目です'),  
    payment_id: Yup.string()
      .required('必須項目です'),  
    line_name: Yup.string()
      .required('必須項目です'),
    // discord_id: Yup.string()
    //   .required('必須項目です')
    //   .matches('#','「名前#9999」で入力してください')
  });  
  const {control, handleSubmit, setValue, formState:{ errors } } = useForm({
    mode: 'onBlur',
    defaultValues:{
      full_name         :'',
      kana              :'',
      mail              :'',
      tel               :'',
      age               :'',
      occupation_id     :'',
      prefecture_id     :'',
      line_name         :'',
      discord_id        :'',
      payment_id        :'',
      payment_status_id : '',
      role_state : '',
      status_id         :'',
      memo              :'',
      stock             :'',
    },
    resolver: yupResolver(basicSchema)
  });
  if (form) {
    // 外部結合していない値
    setValue("full_name", form["full_name"]);
    setValue("kana", form["kana"]);
    setValue("tel", form["tel"]);
    setValue("age", form["age"]);
    setValue("mail", form["mail"]);
    setValue("discord_id", form["discord_id"]);
    setValue("memo", form["memo"]);  
    setValue("role_state", form["role_state"]);  
    // 外部結合している値
    setValue("line_name", form["line_name"]);
    setValue("occupation_id", form["occupation_id"]);
    setValue("prefecture_id", form["prefecture_id"]);
    setValue("payment_id", form["payment_id"]);
    setValue("status_id", form["status_id"]);
    setValue("payment_status_id", form["payment_status_id"]);
    setValue("stock", form["stock_id"]);
      
  }
  useMemo(() => {

    setForm(props.customer);
    const url = process.env.REACT_APP_API_KEY
    const getData = async () => {
      const response1 = await axios.get(
        `${url}/koji_manage/getOccupations`
      );
      setOccupations(response1.data);

      const response2 = await axios.get(
        `${url}/koji_manage/getPrefectures`
      );
      setPrefectures(response2.data);

      const response3 = await axios.get(
        `${url}/koji_manage/getPaymets`
      );
      setPayments(response3.data);
      
      const response4 = await axios.get(
        `${url}/koji_manage/getStatus`
      );
      setStatus(response4.data);

      const response5 = await axios.get(
        `${url}/koji_manage/getPaymentStatus`
      );
      setPaymentStatus(response5.data);

      const response6 = await axios.get(
        `${url}/koji_manage/getStockStatus`
      );
      setStocks(response6.data);

    };
    getData();
    setPutUrl(`${url}/koji_manage/putCustomerEdit/${props.customer.id}/`)
  }, [dialog]);

  //dialog保存
  const handleSave = (data) => {
    let url = `${putUrl}`;
    console.log(data)
    axios.put(url,data)
    .then(res => {
    })
    .catch(res => {
      console.log(res)
    })
    .finally(res=>{
      // console.log(res)
      setDialog(false);
      window.location.reload();
    })
  };
  //dialogキャンセル
  const handleClose = () => {
    setDialog(false);
  };

  return (
    <Dialog
      open={dialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle id="form-dialog-title">サロン生情報</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='full_name'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <TextField
                          className={classes.inputFields}
                          label='名前'
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                          error={errors.full_name ? true : false}
                          helperText={errors.full_name?.message}
                        ></TextField>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='kana'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <TextField
                          className={classes.inputFields}
                          label='カナ'
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                          error={errors.kana ? true : false}
                          helperText={errors.kana?.message}
                        ></TextField>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='mail'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <TextField
                          className={classes.inputFields}
                          label='メール'
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                          error={errors.mail ? true : false}
                          helperText={errors.mail?.message}
                        ></TextField>
                      }
                    />
                  )}
                />
              </Grid>            
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='tel'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                          <TextField
                            className={classes.inputFields}
                            label="電話番号"
                            InputLabelProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                            variant="outlined"
                            type="tel"
                            value={value}
                            onChange={onChange}
                            error={errors.tel ? true : false}
                            helperText={errors.tel?.message}
                          ></TextField>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='age'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <TextField
                          className={classes.inputFields}
                          label='年齢'
                          variant="outlined"
                          type="number"
                          value={value}
                          onChange={onChange}
                          error={errors.age ? true : false}
                          helperText={errors.age?.message}
                        ></TextField>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='occupation_id'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <FormControl>
                          <TextField
                            className={classes.selectValue}
                            label="職業"
                            select
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={errors.occupation_id ? true : false}
                            helperText={errors.occupation_id?.message}
                        >
                          { occupations.map((value, index) => (
                          <MenuItem key={index} value={ value.id }>{ value.name }</MenuItem>
                        )) }
                        </TextField>
                      </FormControl>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='prefecture_id'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <FormControl>
                          <TextField
                            className={classes.selectValue}
                            label="都道府県"
                            select
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={errors.prefecture_id ? true : false}
                            helperText={errors.prefecture_id?.message}
                          >
                          {prefectures.map((value, index) => (
                            <MenuItem key={index} value={ value.id }>{ value.name }</MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='stock'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <FormControl>
                          <TextField
                            className={classes.selectValue}
                            label="株"
                            select
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={errors.stock ? true : false}
                            helperText={errors.stock?.message}
                        >
                          { stocks.map((value, index) => (
                          <MenuItem key={index} value={ value.id }>{ value.name }</MenuItem>
                        )) }
                        </TextField>
                      </FormControl>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='line_name'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <TextField
                          className={classes.inputFields}
                          label='LINE名'
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                          error={errors.line_name ? true : false}
                          helperText={errors.line_name?.message}
                        ></TextField>
                      }
                    />
                  )}
                />
              </Grid>              
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='payment_id'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <FormControl>
                          <TextField
                            select
                            className={classes.selectValue}
                            label="支払い方法"
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={errors.payment_id ? true : false}
                            helperText={errors.payment_id?.message}
                        >
                          <MenuItem></MenuItem>
                          { payments.map((value, index) => (
                          <MenuItem key={index} value={ value.id }>{ value.name }</MenuItem>
                        )) }
                        </TextField>
                      </FormControl>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='status_id'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <FormControl>
                          <TextField
                            select
                            className={classes.selectValue}
                            label="決済有無"
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={errors.status_id ? true : false}
                            helperText={errors.status_id?.message}
                        >
                          <MenuItem></MenuItem>
                          { status.map((value, index) => (
                          <MenuItem key={index} value={ value.id }>{ value.name }</MenuItem>
                        )) }
                        </TextField>
                      </FormControl>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='role_state'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <FormControl>
                          <TextField
                            select
                            className={classes.selectValue}
                            label="権限状況"
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={errors.role_state ? true : false}
                            helperText={errors.role_state?.message}
                        >
                          <MenuItem value="false">失敗</MenuItem>
                          <MenuItem value="true">認証完了</MenuItem>

                        </TextField>
                      </FormControl>
                      }
                    />
                  )}
                />
              </Grid>
                          
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='discord_id'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <TextField
                          className={classes.inputFields}
                          label='DiscordID'
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                          error={errors.discord_id ? true : false}
                          helperText={errors.discord_id?.message}
                        ></TextField>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Controller
                  control={control}
                  name='payment_status_id'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <FormControl>
                          <TextField
                            select
                            className={classes.selectValue}
                            label="ステータス"
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={errors.payment_status_id ? true : false}
                            helperText={errors.payment_status_id?.message}
                        >
                          <MenuItem></MenuItem>
                          { paymentstatus.map((value, index) => (
                          <MenuItem key={index} value={ value.id }>{ value.name }</MenuItem>
                        )) }
                        </TextField>
                      </FormControl>
                      }
                    />
                  )}
                />
              </Grid>
              
              <Grid item lg={12}>
                <Controller
                  control={control}
                  name='memo'
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <TextField
                          className={classes.memoInputFields}
                          label='メモ'
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                        ></TextField>
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>    
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            閉じる
          </Button>
          <Button type="submit" color="primary">
            保存
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
