import React, { useContext, useEffect, useState } from "react";
import { UserInputData } from "../../pages/DiscordForm";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from '@material-ui/core';
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  
  inputFields: {
    margin: 15,
    width: 250,
  },   
  button: {
    marginTop: 20
  }  
}))

export default function EntryForm(props) {
  const classes = useStyles();
  const [mailList, setMailList] = useState([]);

  //validate
  const basicSchema = Yup.object().shape({
    mail: Yup.string()
      .required('必須項目です')
      .email('メールアドレスの形式が不正です')      
      .test('email-test', '登録されたメールアドレスがありません。',(inputMailAddress) => {
        if (mailList.findIndex( value => value.mail === inputMailAddress) !== -1) {
          console.log(mailList.findIndex( value => value.mail === inputMailAddress) )
          return true;
        } else {
          return false;
        }
      }), 
    discordId: Yup.string()
      .required('必須項目です')
      .matches('#','「名前#9999」で入力してください')
  });  
  const { currentState, setCurrentState } = useContext(UserInputData);

  useEffect(() => {
    const url = process.env.REACT_APP_API_KEY
    const getData = async () => {
      const response1 = await axios.get(`${url}/koji_manage/getMailList`);
      setMailList(response1.data)
    }
    getData()
  }, [])
  // フォーム送信用
  const {control, handleSubmit, setValue, formState:{ errors } } = useForm({
    mode: 'onBlur',
    defaultValues: {
      mail          : '',
      discordId     : '',
    },
    resolver: yupResolver(basicSchema)
  });
  const onSubmit = (data) => {
    props.handleNext();
    setCurrentState({...currentState, "form": data });
    console.log(currentState)
  };
  const form = currentState["form"];

  useEffect(() => {
    if (form) {
      setValue("mail", currentState["form"]["mail"]);
      setValue("discordId", currentState["form"]["discordId"]);
    }
  }, [])
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>    
      <Grid item lg={12}>
        <Controller
          control={control}
          name='mail'
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={
                <TextField
                  className={classes.inputFields}
                  label='メール 例)abc123@gmail.com'
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={errors.mail ? true : false}
                  helperText={errors.mail?.message}
                ></TextField>
              }
            />
          )}
        />
      </Grid>
      <Grid item lg={12}>
        <Controller
          control={control}
          name='discordId'
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={
                <TextField
                  className={classes.inputFields}
                  label='Discord ID　例)コジコジ#9828'
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={errors.discordId ? true : false}
                  helperText={errors.discordId?.message}
                ></TextField>
              }
            />
          )}
        />
      </Grid>
      <Grid item>
        <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
        >
            次へ
        </Button> 
      </Grid>
    </form>
  );
}
