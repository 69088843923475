import React, { useState, createContext } from 'react';
import { Grid } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';
import EntryForm from '../components/DiscordForm/EntryForm';
import Confirm from '../components/DiscordForm/Confirm';
import Finish from '../components/DiscordForm/Finish';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router-dom';
import HeaderAdmitForm from '../components/HeaderAdmitForm';

export const UserInputData = createContext();
const useStyles = makeStyles(theme => ({
    stepper:{
      marginTop: 0,
    },
    paper:{
      marginTop: 10,
      paddingTop: 20,
      paddingBottom: 20,
    },
    grid: {
      paddingBottom: 10,
      marginTop: 10,
    }
}))
export default function MemberForm() {
  const classes = useStyles();
  // 紹介判定　通常：regist
  const {id} = useParams();

  const [currentState, setCurrentState] = useState({});
  const value = {
    currentState,
    setCurrentState,
    id,
  };

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };  
  function getSteps() {
    return [
      '個人情報',
      '入力確認',
      '完了'
    ];
  }
  
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <EntryForm  handleNext={handleNext} />;
      case 1:
        return <Confirm  handleNext={handleNext} handleBack={handleBack} />;
      case 2:
        return <Finish />;
      default:
        return 'Unknown stepIndex';
    }
  }
  return (
    <>
      <HeaderAdmitForm />
      <Grid className={classes.grid} container align="center" justifyContent="center" >
        <Grid item lg={8} sm={8} xs={9}>
            <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Grid>
        <Grid item lg={8} sm={8} xs={9}>
          <Paper
            elevation={0}
            className={classes.paper}
          >
            <UserInputData.Provider value={value}>
              {getStepContent(activeStep, handleNext, handleBack)}
            </UserInputData.Provider>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

