import React, { useState } from 'react'
import { withCookies } from 'react-cookie';
import { Typography, Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AdbIcon from '@material-ui/icons/Adb';
import AppleIcon from '@material-ui/icons/Apple';


/**
* @author
* @function Header
**/

const Header = (props) => {
  /** 追加 **/
  const token = props.cookies.get('blog-token');
  const links = [
    {id: 0, category: '', title: 'TOP', url: '/'},
    {id: 1, category: '申込入会', title: 'FX入会フォーム', url: 'https://docs.google.com/forms/d/e/1FAIpQLSebzg-wDPRCbe049Un7tkeh5G93JtjnJT-bxcbYoV7hOY76fQ/viewform'},
    {id: 2, category: '申込入会', title: '株入会フォーム', url: 'https://docs.google.com/forms/d/e/1FAIpQLSc6FfrQsV5ZvJCiU1CHP6boUNoXqKf4Cv9Lx1LMiXHbALPfPg/viewform'},
    {id: 3, category: '決済登録', title: '一括支払い', url: 'https://bit.ly/3g0RgIp'},
    {id: 4, category: '決済登録', title: '二分割支払い', url: 'https://bit.ly/3vYrDNQ'},
    {id: 5, category: '決済登録', title: '三分割支払い', url: 'https://bit.ly/3uQRIgt'},
    {id: 6, category: '決済登録', title: '株支払い', url: 'https://bit.ly/2T8SsjY'},
    {id: 7, category: '決済登録後', title: 'FXサンクスページ', url: 'http://bit.ly/37Rn51P'},
    {id: 8, category: '決済登録後', title: '株サンクスページ', url: 'https://koji-trade.com/kabu_thx/'},
    {id: 9, category: '入会承認', title: 'ディスコード参加', url: 'https://discord.com/channels/585036894027382826/791911683244818452'},
    {id: 10, category: '退会処理', title: '決済管理システム', url: 'https://total.telecomcredit.co.jp/icredit/'},
    {id: 11, category: 'アフィリエイト', title: 'ランディングページ', url: 'https://koji-trade.com/chinatsu_line/'},
    {id: 12, category: 'アフィリエイト', title: '千夏さん専用申込フォーム', url: '/form/chinatsu'},
    {id: 13, category: 'アフィリエイト', title: '申込者一覧シート', url: 'https://docs.google.com/spreadsheets/d/1ReMId4xtUGeLY2L17wNgEIGvz3cmlrCzPYeWIB-jWXk/edit?usp=sharing'},
    {id: 14, category: 'アフィリエイト', title: 'ランディングページ', url: 'https://koji-trade.com/takagi_line/'},
    {id: 15, category: 'アフィリエイト', title: '高木さん専用申込フォーム', url: '/form/takagi'},
    {id: 16, category: 'アフィリエイト', title: '申込者一覧シート', url: 'https://docs.google.com/spreadsheets/d/1drQp9Z10W9sa1M1bSXpzCYUu3QvoLOjzaILz3Xj0BY8/edit?usp=sharing'},
    {id: 17, category: 'アフィリエイト', title: 'FB', url: '/form/FB'},
    {id: 18, category: 'アフィリエイト', title: '荷電', url: '/form/kaden'},
    {id: 19, category: 'FX', title: '利用規約', url: 'https://bit.ly/2T5SB7q'},
    {id: 20, category: '株', title: '利用規約', url: 'https://bit.ly/3kmw7sM'},
  ]
  
  const logout = () => {
    props.cookies.remove('blog-token');
    window.location.href = "/auth";
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginBottom:theme.spacing(2)
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  }
  
  
  const outsideLinks = (url) => {
    window.open(url, '_blank')
  }  
  return(
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" type="title" color="inherit">
            顧客管理
          </Typography>
            {token ?
              <div style={{marginLeft: 'auto'}}>
                  <Button color="inherit" onClick={logout}>ログアウト</Button>
              </div>
            : null}
        </Toolbar>
      </AppBar>
      {/* ハンバーガーメニュー */}
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        {/* 項目 */}
        <List>
          {links.map((link, index) => (
            <ListItem button key={index} onClick={() => outsideLinks(link.url)}>
                <ListItemIcon>
                  {link.id % 2 === 0 ? <AdbIcon /> : <AppleIcon />}
                </ListItemIcon>
                  <ListItemText primary={link.title} secondary={link.category}/>
              </ListItem>
          ))}    
        </List>
      </Drawer>
    </>
  )
}
export default withCookies(Header) /** 変更 **/