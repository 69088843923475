import React,  { useContext }from 'react';
import { DataGrid, jaJP, GridToolbar  } from '@mui/x-data-grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import { withCookies } from 'react-cookie';
import EditDialog from '../Table/EditDialog';
import axios from 'axios';
import moment from 'moment';
import { TableContext } from '../../pages/MemberList';


function MemberTable (props){
  const {
    ip,
    setDialog,
    customer, setCustomer,
    customers,
    change, setChange
    // setCustomers,
  } = useContext(TableContext)

  const handleClickOpen = (params) => {
    setCustomer(params.row)
    setDialog(true);
  };

  
  
  // 退会処理
  const handleClickResigned = ((data) => {
    const bool = window.confirm('本当に退会させますか？')
    if(bool === false) { return }
    const id = data.id
    const date = moment().format("YYYY-MM-DD")
    const formData = {
      'resigned_at'       : date,
      'payment_status_id' : 3,
    }
    const url = `${ip}/koji_manage/putCustomerResigned/${id}/`
    console.log(url)
    axios
      .put(url, formData)
      .then((res) => {
        setChange(!change)           
      })
      .catch((error) => {
        console.log(error)
      })
  })  
  //一覧の項目
  const columns = [
    { 
      field: 'full_name',
      headerName: '氏名',
      minWidth: 100,
    },
    { 
      field: 'join_date',
      headerName: '入会日',
      type: 'dateTime',
      minWidth: 90,
    },
    { 
      field: 'resigned_at',
      headerName: '退会日',
      type: 'dateTime',
      minWidth: 90,
    },
    { 
      field: 'kana',
      headerName: '氏名(カナ)',
      minWidth: 120,
    },
    { 
      field: 'mail',
      headerName: 'メールアドレス ',
      minWidth: 160,
    },
    { 
      field: 'tel',
      headerName: '電話番号',
      minWidth: 110,
    },
    { 
      field: 'age',
      headerName: '年齢',
      minWidth: 30,
      hide: true,
    },    
    { 
      field: 'occupation',
      headerName: '職業',
      minWidth: 30,
      hide: true,
    },
    { 
      field: 'prefecture',
      headerName: '都道府県',
      minWidth: 105,
      hide: true,
    },
    { 
      field: 'afiliate_code',
      headerName: '紹介者',
      minWidth: 30,
    },
    { 
      field: 'stock',
      headerName: '株',
      minWidth: 10,
    },
    { 
      field: 'line_name',
      headerName: 'LINE',
      minWidth: 80,
    },
    {
      field: 'discord_id',
      headerName: 'Discord',
      minWidth: 80,
    },
    { 
      field: 'payment',
      headerName: '支払い方法',
      minWidth: 120,
    },
    { 
      field: 'status',
      headerName: '決済有無',
      minWidth: 125,
    },    
    {
      field: 'role_state',
      headerName: '権限状況',
      minWidth: 110,
      renderCell: (params) => {
        let data = params.row.role_state === true ? '認証完了' : params.row.role_state === false ? '失敗' : ''
        return <div className="rowitem" >{data}</div>;
      },
    },   
    {
      field: 'payment_status',
      headerName: 'ステータス',
      minWidth: 120,
    },
    {
      field: 'delete',
      headerName: '退会',
      renderCell: (params) => (
        <strong>
          <IconButton
            variant="contained"
            color="secondary"
            size="small"
            aria-label="delete"
            onClick={()=>handleClickResigned(params)}
          ><DeleteIcon fontSize="small"/></IconButton>
        </strong>
      ),
    },
    {
      field: 'id',
      headerName: '編集',
      renderCell: (params) => (
        <strong>
          <IconButton
            variant="contained"
            color="primary"
            size="small"
            aria-label="delete"
            onClick={()=>handleClickOpen(params)}
          >
            <CreateIcon fontSize="small"/>
          </IconButton>   
        </strong>
      ),
    },
    {
      field: 'memo',
      headerName: 'メモ',
      minWidth: 160,
    },
  ];
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        loading={customers.length === 0}
        columns={columns}
        rows={customers}
        disableColumnMenu
        rowsPerPageOptions={[10, 20 ,50, 100]}
        disableSelectionOnClick
        localeText={jaJP.props.MuiDataGrid.localeText}
        components={{
          Toolbar: GridToolbar,
        }}
      />
      <EditDialog customer={customer}/>
    </div>
  );
}
export default withCookies(MemberTable)