import React, { createContext, useEffect, useState } from 'react';
import MemberTable from '../components/Table/MemberTable'
// import DataTable from '../components/Table/DataTable'
import Container from "@material-ui/core/Container";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withCookies } from 'react-cookie';
import axios from 'axios';

export const TableContext = createContext()

const columns = [
  {field: 'join_date',     headerName: '入会日'},
  {field: 'resigned_at',   headerName: '退会日'},
  {field: 'full_name',     headerName: '氏名'},
  {field: 'kana',          headerName: '氏名(カナ)'},
  {field: 'mail',          headerName: 'メールアドレス '},
  {field: 'tel',           headerName: '電話番号'},
  {field: 'afiliate_code', headerName: '紹介者'},
  {field: 'stock',         headerName: '株'},
  {field: 'line_name',     headerName: 'LINE'},
  {field: 'discord_id',    headerName: 'Discord'},
  {field: 'payment',       headerName: '支払い方法'},
  {field: 'status',        headerName: '決済有無'},
  {field: 'role_state',    headerName: '権限状況'},
  {field: 'payment_status',headerName: 'ステータス'},
  {field: 'delete',        headerName: '退会'},
  {field: 'id',            headerName: '編集'},
  {field: 'memo',          headerName: 'メモ'},
]
const useStyles = makeStyles((theme) => ({
  root:{
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  buttonRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 8
  },
  button: {
    marginRight: 10
  }
}));

function MemberList(props) {
  const [change, setChange] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [customer, setCustomer] = useState({});
  const [customers, setCustomers] = useState([]);
  const classes = useStyles();
  const ip = process.env.REACT_APP_API_KEY
  const url_getList = `${ip}/koji_manage/getList`

  useEffect(() => {
    const getCustomers = async () => {
      const response = await axios.get(url_getList)
      // response.data.map((value, index) => {
      //   // 株入会の場合に出力する値を変更　1：〇
      // })
      setCustomers(response.data);
    }
    getCustomers()
  }, [change])

  return (
    <Container maxWidth={false}>
        <Typography className={classes.root} variant="h5">{'サロン生'}</Typography>
        <div className={classes.buttonRight}>
          <Button
            className={classes.button}
            color="primary"
            size="small"
            variant="contained"
            href={'/form/regist'}
          >FX入会申し込み</Button>
          <Button
            className={classes.button}
            color="primary"
            size="small"
            variant="contained"
            href='/Stock'
          >株入会申し込み</Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            href='/Discord'
          >入会承認</Button>
        </div>
        <Card>
          <TableContext.Provider
            value={{
              ip,
              dialog, setDialog,
              customer, setCustomer,
              customers, setCustomers,
              change, setChange,
            }}>
            <MemberTable></MemberTable>
            {/* <DataTable></DataTable> */}
          </TableContext.Provider>
        </Card>
    </Container>
  );
} export default withCookies(MemberList)