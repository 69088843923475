import {createTheme } from '@material-ui/core/styles'

export const theme = createTheme ({
  palette: {
    primary: {
      main: '#448aff',
    },
    secondary: {
      main: '#e91e63',
    },
  },
})