import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { UserInputData } from "../../pages/MemberForm";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

var payments = [
  "", // 0番目を飛ばす
  "カード一括払い",
  "カード二分割払い",
  "カード三分割払い",
  "銀行振り込み",
];

var occuaptions = [
  "",
  "会社員",
  "公務員",
  "経営者・役員",
  "自営業・個人事業主",
  "パート・アルバイト",
  "専業主婦",
  "学生",
  "その他",
];

const prefectures = [
  "",
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
  "その他/海外",
];

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "15px 10px 0 2px",
  },
}));

function Confirm(props) {
  const classes = useStyles();
  const { currentState } = useContext(UserInputData);
  const onSubmit = () => {
    // 支払方法
    const formData = currentState.form.payment_id;
    // http送信先
    const ip = process.env.REACT_APP_API_KEY;
    let url = `${ip}/koji_manage/postCustomer`;
    axios
      .post(url, currentState.form, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        alert("送信が完了しました。\n決済登録画面に遷移します。");
        // フォーム送信後の画面遷移(1:一括 2:二分割 3:三分割 4:銀行振込)
        if (formData == "1") {
          window.location.href = "https://bit.ly/45ljSTj";
        }
        if (formData == "2") {
          window.location.href = "https://bit.ly/3OqV4T2";
        }
        if (formData == "3") {
          window.location.href = "https://bit.ly/3qsA0DC";
        }
        if (formData == "4") {
          window.location.href = "https://koji-trade.com/bank/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const inputDataLists = {
    full_name: "",
    kana: "",
    mail: "",
    prefecture_id: "",
    tel: "",
    age: "",
    occupation_id: "",
    line_name: "",
    payment_id: "",
    status_id: "",
  };

  for (var k in currentState) {
    for (var v in currentState[k]) {
      var value = "";
      if (currentState[k][v] === true) {
        value = "チェックしました";
      } else if (currentState[k][v] === false) {
        value = "チェックしていません";
      } else if (currentState[k][v] === "") {
        value = "未入力";
      } else {
        value = currentState[k][v];
      }
      inputDataLists[v] = value;
    }
  }
  return (
    <Grid container>
      <TableContainer component={Paper}>
        <Table aria-label="Customer Input Data">
          <TableHead>
            <TableRow>
              <TableCell>項目</TableCell>
              <TableCell>入力内容</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell>{inputDataLists.full_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>カナ</TableCell>
              <TableCell>{inputDataLists.kana}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>メール</TableCell>
              <TableCell>{inputDataLists.mail}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>都道府県</TableCell>
              <TableCell>{prefectures[inputDataLists.prefecture_id]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>電話番号</TableCell>
              <TableCell>{inputDataLists.tel}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>年齢</TableCell>
              <TableCell>{inputDataLists.age}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>職業</TableCell>
              <TableCell>{occuaptions[inputDataLists.occupation_id]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>LINE名</TableCell>
              <TableCell>{inputDataLists.line_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>支払い方法</TableCell>
              <TableCell>{payments[inputDataLists.payment_id]}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={props.handleBack}
      >
        戻る
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={onSubmit}
      >
        送信
      </Button>
    </Grid>
  );
}
export default Confirm;
